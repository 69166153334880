class Frontend {

    _t = {
        s1: {
            form: {
                select: '#efsa_tarife_select_judet'
            }
        },
        s2: {
            container: {
                parent: '#efsa_tarife_container',
                child: '#efsa_tarife_column_container'
            },
            titleCounty: '#efsa_tarife_judet_selectat',
            btn: '.efsa-rates-pricing-details'
        },
        s4: {
            container: {
                parent: '#efsa_tarife_detalii_calcul_container',
                // compRegl: '#efsa_tarife_componente_reglementate',
                moreDetails: '.efsa-rates-accordion-content',
                toggleIcon: '.efsa-rates-accordion-heading .toggle-icon',
            },
            tabel: {
                numeTarif: '.efsa_tarife_tabel_detalii_nume_tarif',
                total: '.efsa_tarife_tabel_detalii_total',
                cc660: '.efsa_tarife_tabel_detalii_cc660',
                cc985: '.efsa_tarife_tabel_detalii_cc985',
                cc391: '.efsa_tarife_tabel_detalii_cc391',
                cc948: '.efsa_tarife_tabel_detalii_cc948',
                cc958: '.efsa_tarife_tabel_detalii_cc958',
                cc959: '.efsa_tarife_tabel_detalii_cc959',
                cc957DT001: '.efsa_tarife_tabel_detalii_cc957_DT001',
                cc957DT002: '.efsa_tarife_tabel_detalii_cc957_DT002',
                cc957DT003: '.efsa_tarife_tabel_detalii_cc957_DT003',
                // detalii: '.efsa_tarife_tabel_detalii_suplimentare',
                pretEnergie: '.efsa_tarife_tabel_detalii_energie',
                pretEnergieIncRegl: '.efsa_tarife_tabel_detalii_energie_inc_regl',
                pretEnergieZi: '.efsa_tarife_tabel_detalii_energie_zi',
                pretEnergieZiIncRegl: '.efsa_tarife_tabel_detalii_energie_zi_inc_regl',
                pretEnergieNoapte: '.efsa_tarife_tabel_detalii_energie_noapte',
                pretEnergieNoapteIncRegl: '.efsa_tarife_tabel_detalii_energie_noapte_inc_regl',
                pretAbonament: '.efsa_tarife_tabel_detalii_abonament',
                consum: '.efsa_tarife_tabel_detalii_consum',
                procConsNoapte: '.efsa_tarife_tabel_detalii_consum_time_night',
                tva: '.efsa_tarife_tabel_detalii_tva',
                valTva: '.efsa_tarife_tabel_detalii_valoare_tva',
                nrZile: '.efsa_tarife_tabel_detalii_nr_zile',
                detaliiAbonament: '.efsa_tarife_tabel_detalii_info_abonament'
            }
        },
        util: {
            loader: {
                overlay: '#efsa_tarife_overlay',
                text: '#efsa_tarife_overlay_text'
            },
            reCaptcha: '#g-recaptcha-response',
        }
    };

    constructor() {
        $(document).ready(this.ready.bind(this));
    }

    /**
     * Method called after document ready.
     */
    ready() {
        this.bind();
    }

    /**
     * Bind events
     */
    bind() {

        $(document).on('change', this._t.s1.form.select, this.countyFormSelect);

        $(document).on('click', this._t.s2.btn, this.showMoreRateDetails);

        $(document).on('click', '.tab-link', this.changeTabs);

        $(document).on('click', '.efsa-rates-accordion-heading', this.toggleAccordion);
    }

    toggleAccordion = (e) => {
        e.preventDefault();

        const $currentTarget = $(e.currentTarget);
        const $icon = $currentTarget.find('.toggle-icon');
        const $content = $currentTarget.parents('.efsa-rates-accordion').find('.efsa-rates-accordion-content');

        $content.slideToggle();

        if ('+' === $icon.text()) {
            $icon.text('-');
        } else {
            $icon.text('+');
        }
    }

    countyFormSelect = (e) => {
        e.preventDefault();

        const selectedCounty = $(e.currentTarget).val();
        const selectedCountyText = $(this._t.s1.form.select + ' option:selected').text();
        const $parent = $(this._t.s2.container.parent);

        $parent.hide();

        this.efsa_tarife_collapseTarifeReglementate();

        if ("0" === selectedCounty) {
            alert('Selectează un județ pentru a putea continua!');
        } else {
            this.efsa_tarife_recaptcha(function () {

                this.efsa_tarife_fadeLoader('in');

                this.ajaxRequest({
                    data: {
                        action: 'efsa_tarife_ajax',
                        designation: 'request_prices',
                        data: selectedCounty,
                        grecaptcha: $(this._t.util.reCaptcha).val(),
                    }
                }).done((response) => {
                    if (response.hasOwnProperty('ERR_FLAG')) {
                        if (response.ERR_FLAG === 1) {
                            alert(response.ERR_MESS);
                        }
                    } else {
                        $(this._t.s2.container.child).html(response);
                        $(this._t.s2.titleCounty).html(selectedCountyText);
                        $parent.show();

                        this.efsa_tarife_toTop(this._t.s2.container.parent);
                    }

                    this.efsa_tarife_fadeLoader('out');
                });
            }.bind(this));
        }
    }

    ajaxRequest = (params = {}) => {
        const defaultParams = {
            url: efsa_tarife_js_ajax_req.ajax_url,
            type: 'POST',
            data: {
                security: efsa_tarife_js_ajax_req.ajax_nonce
            }
        }

        return $.ajax(this.deepMergeObject(defaultParams, params));
    }

    deepMergeObject = (targetObject = {}, sourceObject = {}) => {
        // clone the source and target objects to avoid the mutation
        const copyTargetObject = JSON.parse(JSON.stringify(targetObject));
        const copySourceObject = JSON.parse(JSON.stringify(sourceObject));
        // Iterating through all the keys of source object
        Object.keys(copySourceObject).forEach((key) => {
            if (typeof copySourceObject[key] === "object" && !Array.isArray(copySourceObject[key])) {
                // If property has nested object, call the function recursively
                copyTargetObject[key] = this.deepMergeObject(
                    copyTargetObject[key],
                    copySourceObject[key]
                );
            } else {
                // else merge the object source to target
                copyTargetObject[key] = copySourceObject[key];
            }
        });

        return copyTargetObject;
    }

    efsa_tarife_collapseTarifeReglementate = () => {
        $(this._t.s4.container.moreDetails).hide();
        $(this._t.s4.container.toggleIcon).text('+');
    }

    efsa_tarife_toTop = (objIdentifier) => {
        $([document.documentElement, document.body]).animate(
            {
                scrollTop: $(objIdentifier).offset().top
            },
            1000);
    }

    efsa_tarife_fadeLoader = (action) => {
        let loader = $(this._t.util.loader.overlay);
        if ('in' === action) {
            loader.fadeIn('slow');
        } else if ('out' === action) {
            loader.fadeOut('slow');
        }
    }

    efsa_tarife_recaptcha = (callback = null, args = null) => {
        const efsa_tarife_site_key = $('#efsa_tarife_sk').val();
        grecaptcha.ready(function () {
            grecaptcha.execute(efsa_tarife_site_key, {
                action: 'efsa_tarife'
            }).then(function (token) {
                document.getElementById("g-recaptcha-response").value = token;
                if (callback) {
                    if (args) {
                        callback(args);
                    } else {
                        callback();
                    }
                }
            });
        });
    }

    changeTabs = (e) => {
        e.preventDefault();

        const $currentTarget = $(e.currentTarget);

        $('.tab-link').parents('.nav-tabs li').removeClass('active');
        $currentTarget.parent().addClass('active');

        $('.tab-content .tab-pane').removeClass('show');
        $(`#tab-${$currentTarget.attr('id')}`).addClass('show');

        $(this._t.s4.container.parent).hide();

        this.efsa_tarife_collapseTarifeReglementate();
    }

    showMoreRateDetails = (e) => {
        e.preventDefault();

        const $currentTarget = $(e.currentTarget);
        const rate = $currentTarget.data('rate');
        const $parent = $(this._t.s4.container.parent);

        this.efsa_tarife_recaptcha(function () {

            this.efsa_tarife_fadeLoader('in');
            this.efsa_tarife_collapseTarifeReglementate();

            this.ajaxRequest({
                data: {
                    action: 'efsa_tarife_ajax',
                    designation: 'get_more_details',
                    data: rate,
                    grecaptcha: $(this._t.util.reCaptcha).val(),
                }
            }).done((response) => {

                if (response.ERR_FLAG && response.DATA) {
                    return false;
                }

                // TODO: transform data to html
                const data = response.DATA;

                $(this._t.s4.tabel.numeTarif).html($currentTarget.data('nume'));
                $(this._t.s4.tabel.total).html('<strong>' + $currentTarget.data('total') + '</strong> Lei');
                $(this._t.s4.tabel.cc660).html('<strong>' + (parseFloat($currentTarget.data('cc660'))).toFixed(5) + '</strong> Lei/kWh');
                $(this._t.s4.tabel.cc985).html('<strong>' + (parseFloat($currentTarget.data('cc985'))).toFixed(5) + '</strong> Lei/kWh');
                $(this._t.s4.tabel.cc391).html('<strong>' + (parseFloat($currentTarget.data('cc391'))).toFixed(7) + '</strong> Lei/kWh');
                $(this._t.s4.tabel.cc948).html('<strong>' + (parseFloat($currentTarget.data('cc948'))).toFixed(5) + '</strong> Lei/kWh');
                $(this._t.s4.tabel.cc958).html('<strong>' + (parseFloat($currentTarget.data('cc958'))).toFixed(5) + '</strong> Lei/kWh');
                $(this._t.s4.tabel.cc959).html('<strong>' + (parseFloat($currentTarget.data('cc959'))).toFixed(5) + '</strong> Lei/kWh');
                $(this._t.s4.tabel.cc957DT001).html('<strong>'
                    + parseFloat((parseFloat($currentTarget.data('cc957dt001')))
                        + (parseFloat($currentTarget.data('cc957dt002')))
                        + (parseFloat($currentTarget.data('cc957dt003')))).toFixed(5)
                    + '</strong> Lei/kWh');
                $(this._t.s4.tabel.consum).html('<strong>' + $currentTarget.data('cantitate') + '</strong> kWh');
                $(this._t.s4.tabel.tva).html($currentTarget.data('tva'));
                $(this._t.s4.tabel.valTva).html('<strong>'
                    + parseFloat((parseFloat($currentTarget.data('totaleejt')))
                        - ((100 * parseFloat($currentTarget.data('totaleejt'))) / ($currentTarget.data('tva') + 100))).toFixed(5)
                    + '</strong> Lei/kWh');
                $(this._t.s4.tabel.pretEnergieIncRegl).html('<strong>' + (parseFloat($currentTarget.data('totaleejt'))).toFixed(5) + '</strong> Lei/kWh');

                if ($currentTarget.data('energie')) {
                    $(this._t.s4.tabel.pretEnergie).html('<strong>' + (parseFloat($currentTarget.data('energie'))).toFixed(7) + '</strong> Lei/kWh');
                    $(this._t.s4.tabel.pretEnergie).parent().show();
                } else {
                    $(this._t.s4.tabel.pretEnergie).parent().hide();
                }

                if ($currentTarget.data('energiezi')) {
                    $(this._t.s4.tabel.pretEnergieZi).html('<strong>' + (parseFloat($currentTarget.data('energiezi'))).toFixed(5) + '</strong> Lei/kWh');
                    $(this._t.s4.tabel.pretEnergieZi).parent().show();
                } else {
                    $(this._t.s4.tabel.pretEnergieZi).parent().hide();
                }

                if ($currentTarget.data('energienoapte')) {
                    $(this._t.s4.tabel.pretEnergieNoapte).html('<strong>' + (parseFloat($currentTarget.data('energienoapte'))).toFixed(5) + '</strong> Lei/kWh');
                    $(this._t.s4.tabel.pretEnergieNoapte).parent().show();
                } else {
                    $(this._t.s4.tabel.pretEnergieNoapte).parent().hide();
                }
                if ($currentTarget.data('abonament')) {
                    $(this._t.s4.tabel.pretAbonament).html('<strong>' + (parseFloat($currentTarget.data('abonament'))).toFixed(2) + '</strong> Lei/loc de consum/zi');
                    $(this._t.s4.tabel.pretAbonament).parent().show();
                    $(this._t.s4.tabel.nrZile).html($currentTarget.data('zileluna'));
                    $(this._t.s4.tabel.nrZile).parent().parent().show();
                } else {
                    $(this._t.s4.tabel.pretAbonament).parent().hide();
                    $(this._t.s4.tabel.nrZile).parent().parent().hide();
                }

                if ($currentTarget.data('proc-cons-noapte') && 'time' === $currentTarget.data('otype')) {
                    $(this._t.s4.tabel.procConsNoapte).html('<strong>' + (parseFloat($currentTarget.data('proc-cons-noapte'))).toFixed(0) + '</strong>%');
                    $(this._t.s4.tabel.procConsNoapte).parent().show();
                } else {
                    $(this._t.s4.tabel.procConsNoapte).parent().hide();
                }

                $parent.show();

                this.efsa_tarife_toTop(this._t.s4.container.parent);
                this.efsa_tarife_fadeLoader('out');
            });
        }.bind(this));

    }
}

export default new Frontend();
